import { PUBLIC_ENV } from '$env/static/public';
import { sentry } from '$lib/env';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: sentry.dsn,
	integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
	tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
	environment: PUBLIC_ENV === 'production' ? 'production' : 'preview',
	sendDefaultPii: true,
	enableTracing: true,
});
export const handleError = Sentry.handleErrorWithSentry();
